import React from 'react';
import { ReactNode } from 'react';
import styles from './PageSectionStack.module.scss';

interface Props {
  children: ReactNode,
  className?: string,
  widerModules?: boolean,
}

const PageSectionStack: React.FC<Props> = ({
  children, className, widerModules,
}) => {
  return (
    <div
      className={ `${widerModules ? styles.wider : ''} ${styles.innerWrapper} ${className || ''}`}
    >
      { children }
    </div>
  );
};

export default PageSectionStack;
